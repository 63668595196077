<template>
  <div class="Management">
    <!-- 导师员工后台 -->
    <div v-if="userInfo.admin_group_id === 1 || userInfo.admin_group_id === 3">
      <div style="padding-top: 1.3043rem">
        <div
          style="
            margin: 0 15px;
            background: #fff;
            padding: 0 15px;
            box-sizing: border-box;
            height: 4.3478rem;
            display: flex;
            align-items: center;
            box-shadow: 0 0 0.4348rem #c6c3c3;
            position: relative;
            border-radius: 4px;
          "
        >
          <img
            :src="userInfo.avatar"
            alt=""
            style="
              margin-right: 0.7246rem;
              border-radius: 50%;
              width: 2.8261rem;
              height: 2.8261rem;
            "
          />

          <div
            style="
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div>
              <div
                class="overflow-text"
                style="color: #000; font-size: 1.0145rem; width: 10.1449rem"
              >
                姓名：{{
                  userInfo.admin_name ? userInfo.admin_name : userInfo.nickname
                }}
              </div>
              <div
                v-if="userInfo.admin_group_id === 3"
                style="
                  color: #606060;
                  font-size: 0.8696rem;
                  margin-top: 0.3623rem;
                "
              >
                您好
              </div>
              <div v-else-if="userInfo.admin_group_id === 1"></div>
            </div>
            <div
              @click="handleLogOut"
              style="color: #4372fc; font-size: 0.8696rem"
            >
              退出
            </div>
          </div>

          <div
            v-if="userInfo.admin_group_id === 1"
            @click="goAddActivity"
            style="
              color: #ff6000;
              font-size: 0.8696rem;
              position: absolute;
              top: 50%;
              left: 68%;
              transform: translate(-50%, -50%);
            "
          >
            发起新活动
          </div>
        </div>
      </div>

      <div style="margin-top: 1.087rem">
        <ul
          class="infinite-list"
          v-infinite-scroll="handleScroll"
          style="overflow: auto; height: calc(100vh - 5.6522rem)"
        >
          <li
            v-for="(item, index) in activityList"
            :key="index"
            class="infinite-list-item"
          >
            <div class="list_title">
              <!-- 员工角色 -->
              <template v-if="userInfo.admin_group_id === 3"
                >授权管理活动</template
              >
              <!-- 导师角色 -->
              <template
                v-else-if="
                  userInfo.admin_group_id === 1 &&
                  item.check_status === 1 &&
                  item.status === 1
                "
                >进行中活动</template
              >
              <template
                v-else-if="
                  userInfo.admin_group_id === 1 &&
                  item.check_status === 1 &&
                  item.status === 3
                "
                >即将开始活动</template
              >
              <template
                v-else-if="
                  userInfo.admin_group_id === 1 && item.check_status === 3
                "
                >审核中活动（等待设计师完成审批及设计）</template
              >
              <template v-else>{{ item.status_name }}</template>
            </div>
            <div class="list_con">
              <div>
                <div
                  @click="handleGoActivity(item)"
                  class="overflow-text-2 item_name"
                >
                  {{ item.name }}
                </div>
                <!-- <div v-if="userInfo.admin_group_id === 3 && item.check_status === 1" @click="handleShareLink(item)" class="copy-text" :data-clipboard-text="copyText" style="color: #6083EA; font-size: .8696rem; text-align: right;">
                                分享链接
                            </div> -->
              </div>

              <div class="item-time">
                <div>
                  活动时间：<span
                    >{{ item.begin_time }} 至 {{ item.end_time }}</span
                  >
                </div>

                <div
                  v-if="userInfo.admin_group_id === 1"
                  @click="goAddActivity(item)"
                  style="
                    color: #6083ea;
                    font-size: 0.8696rem;
                    text-align: right;
                  "
                >
                  编辑
                </div>
              </div>

              <template v-if="true">
                <div class="item-btns">
                  <div
                    @click="handleClickLabel(1, item)"
                    v-if="userInfo.admin_group_id === 1"
                    class="item-btn"
                  >
                    邀请员工
                  </div>
                  <div
                    @click="handleClickLabel(2, item)"
                    v-if="userInfo.admin_group_id === 1"
                    class="item-btn"
                  >
                    审核员工
                  </div>
                  <div
                    @click="handleClickLabel(3, item)"
                    v-if="userInfo.admin_group_id === 1"
                    class="item-btn"
                  >
                    管理员工
                  </div>
                  <div
                    @click="handleClickLabel(8, item)"
                    v-if="userInfo.admin_group_id === 3"
                    class="item-btn"
                  >
                    员工分红
                  </div>
                  <div
                    @click="handleClickLabel(9, item)"
                    v-if="userInfo.admin_group_id === 3"
                    class="item-btn"
                  >
                    员工佣金
                  </div>
                  <div @click="handleClickLabel(4, item)" class="item-btn">
                    成功报名
                  </div>
                  <div @click="handleClickLabel(5, item)" class="item-btn">
                    潜在客户
                  </div>
                  <div @click="handleClickLabel(6, item)" class="item-btn">
                    全部组队
                  </div>
                  <div @click="handleClickLabel(7, item)" class="item-btn">
                    全部单买
                  </div>
                  <div
                    @click="handleClickLabel(0, item)"
                    v-if="userInfo.admin_group_id === 1"
                    class="item-btn"
                  >
                    员工数据
                  </div>
                </div>

                <!-- <div
                style="
                  height: 2.1014rem;
                  margin-top: .5072rem;
                  border-radius: .3623rem;
                  border: .0725rem solid #b5b5b5;
                  box-sizing: border-box;
                  padding: .6522rem .5797rem;
                  color: #343434;
                  font-size: .7246rem;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div style="display: flex; align-items: center; flex: 1">
                  <div style="display: flex; align-items: center">
                    <div>成功邀请</div>
                    <div
                      style="
                        margin: 0 .2174rem 0 .5072rem;
                        width: 3.1884rem;
                        height: 1.5217rem;
                        line-height: 1.5217rem;
                        text-align: center;
                        border-radius: .3623rem;
                        border: .0725rem solid #b5b5b5;
                      "
                    >
                      2
                    </div>
                    <div>人</div>
                  </div>

                  <div style="margin-left: 1.3043rem">
                    合计人数：
                    <span style="color: #ff6603; padding: 0 .1449rem">{{
                      item.totalPeopleCount ? item.totalPeopleCount : 0
                    }}</span>
                    人
                  </div>
                </div>
                <div
                  @click="handleCalculateData(item, index)"
                  style="color: #e95d04"
                >
                  点击计算
                </div>
              </div> -->
              </template>

              <div v-else style="margin-top: 0.7971rem">
                <div style="color: #a8a8a8; font-size: 0.7246rem">
                  提交时间：{{ item.updatetime }}
                </div>
                <div
                  @click="goAddActivity(item)"
                  style="
                    color: #ff6000;
                    font-size: 0.8696rem;
                    position: absolute;
                    right: 1.1594rem;
                    bottom: 1.0145rem;
                  "
                >
                  撤回编辑
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 家长后台 -->
    <div v-else-if="userInfo.admin_group_id === 0">
      <div
        style="
          height: 2.2464rem;
          background: #9c0000;
          line-height: 1.4493rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 1.0145rem;
        "
      >
        <div>离活动结束</div>

        <div
          style="
            background: #fff;
            padding: 0 0.2899rem;
            border-radius: 0.1449rem;
            margin: 0 0.2899rem 0 0.4348rem;
            font-size: 0.8696rem;
            color: #f79a08;
          "
        >
          {{ countdown.day }}
        </div>
        <div>天</div>

        <div
          style="
            background: #fff;
            padding: 0 0.2899rem;
            border-radius: 0.1449rem;
            margin: 0 0.2899rem 0 0.4348rem;
            font-size: 0.8696rem;
            color: #f79a08;
          "
        >
          {{ countdown.hour }}
        </div>
        <div>时</div>

        <div
          style="
            background: #fff;
            padding: 0 0.2899rem;
            border-radius: 0.1449rem;
            margin: 0 0.2899rem 0 0.4348rem;
            font-size: 0.8696rem;
            color: #f79a08;
          "
        >
          {{ countdown.minute }}
        </div>
        <div>分</div>

        <div
          style="
            background: #fff;
            padding: 0 0.2899rem;
            border-radius: 0.1449rem;
            margin: 0 0.2899rem 0 0.4348rem;
            font-size: 0.8696rem;
            color: #f79a08;
          "
        >
          {{ countdown.seconds }}
        </div>
        <div>秒</div>
      </div>

      <div
        style="
          height: 37.6812rem;
          box-sizing: border-box;
          background: #9e3dd6;
          padding: 0.8696rem;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center">
            <img
              style="width: 2.5362rem; height: 2.5362rem"
              src="http://pp.ihycc.cn/image/qiandai.png"
              alt=""
            />
            <div
              style="color: #fff; font-size: 0.8696rem; margin-left: 0.4348rem"
            >
              <div>活动已开启邀请赚佣金模式</div>
              <div>
                <span>每邀请1人下单可以赚</span>
                <span style="color: #ea0d36"
                  >{{
                    regularMembersData.good
                      ? regularMembersData.good.reward_pid
                      : 0
                  }}元</span
                >
              </div>
            </div>
          </div>
          <div
            @click="handleClickShowEarnings"
            style="color: #ff7b2b; font-size: 0.8696rem"
          >
            查看我的收益
          </div>
        </div>

        <div
          style="
            margin-top: 1.0145rem;
            background: #fff;
            box-sizing: border-box;
            padding: 1.0145rem 1.0145rem 2.8986rem;
            border-radius: 0.1449rem;
          "
        >
          <div style="color: #4e4e4e; font-size: 0.942rem; text-align: center">
            <div style="font-weight: bold">拼团中</div>
            <div
              v-if="regularMembersData.poorPeopleCount !== 0"
              style="font-weight: 400; margin-top: 0.4348rem"
            >
              还差{{ regularMembersData.poorPeopleCount }}人 享福利
            </div>
            <div>
              <div
                v-for="(item, index) in regularMembersData.tuans"
                :key="index"
                style="
                  display: inline-flex;
                  justify-content: center;
                  flex-direction: column;
                  margin: 0.5072rem 1.3043rem 0;
                "
              >
                <template v-if="item">
                  <div
                    style="
                      margin: auto;
                      height: 3.2609rem;
                      width: 3.2609rem;
                      border-radius: 50%;
                      overflow: hidden;
                      position: relative;
                    "
                  >
                    <img :src="item.avatar_url" alt="" style="width: 100%" />
                    <div
                      v-if="regularMembersData.member_id === item.member_id"
                      style="
                        position: absolute;
                        color: #fff;
                        font-size: 0.6522rem;
                        width: 74%;
                        transform: translateX(-50%);
                        left: 50%;
                        bottom: 0;
                        background: #ffcc00;
                        border-radius: 0.3623rem;
                      "
                    >
                      团长
                    </div>
                  </div>
                  <div style="font-szie: 0.942rem">{{ item.nickname }}</div>
                </template>
                <template v-else>
                  <div
                    @click="handleShareLink(regularMembersData)"
                    class="add"
                    style="
                      height: 3.2609rem;
                      width: 3.2609rem;
                      border-radius: 50%;
                      overflow: hidden;
                      background: #aaaaaa;
                      position: relative;
                    "
                  ></div>
                  <div style="font-szie: 0.942rem">待招募</div>
                </template>
              </div>
            </div>
          </div>

          <div
            style="
              justify-content: center;
              color: #fff;
              font-size: 0.8696rem;
              display: flex;
              align-items: center;
              margin-top: 1.6667rem;
            "
          >
            <div
              @click="handleSetupTeam"
              style="
                margin: 0 0.5797rem;
                background: #00a0e8;
                padding: 0 1.1594rem;
                hieght: 2.1739rem;
                line-height: 2.1739rem;
                border-radius: 2.1739rem;
              "
            >
              {{
                regularMembersData.user_id === regularMembersData.member_id
                  ? "我是团长"
                  : "独立成团"
              }}
            </div>
            <div
              @click="handlePosterState(1)"
              style="
                margin: 0 0.5797rem;
                background: #f12b2b;
                padding: 0 1.1594rem;
                hieght: 2.1739rem;
                line-height: 2.1739rem;
                border-radius: 2.1739rem;
              "
            >
              招募团员 立即赚钱
            </div>
          </div>

          <div style="margin-top: 2.1739rem; font-weight: 400">
            <div
              v-if="regularMembersData.good"
              style="display: flex; algin-items: center"
            >
              <!-- <div style="background: #00a0e8; width: 5.4348rem; height: 5.0725rem; border-radius: .4348rem; margin-right: .5797rem;"></div> -->
              <div
                style="
                  width: 5.4348rem;
                  height: 5.0725rem;
                  border-radius: 0.4348rem;
                  margin-right: 0.5797rem;
                  overflow: hidden;
                "
              >
                <img
                  :src="regularMembersData.good.good_image"
                  alt=""
                  style="width: 100%"
                />
              </div>

              <div
                style="
                  color: #4e4e4e;
                  flex: 1;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                "
              >
                <div style="font-size: 1.0145rem">
                  {{ regularMembersData.good.good_name }}
                </div>
                <div style="font-size: 0.8696rem">
                  <div style="display: inline-flex; align-items: center">
                    <div style="text-decoration: line-through">
                      ¥{{ regularMembersData.good.old_price }}
                    </div>
                    <div style="margin-left: 0.6522rem">
                      仅剩{{ regularMembersData.good.good_qty }}件
                    </div>
                  </div>
                  <div style="color: #da2626">
                    3人团价¥{{ regularMembersData.good.sale_price }}
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                font-size: 0.942rem;
                display: flex;
                algin-items: center;
                margin-top: 1.1594rem;
              "
            >
              <div
                style="
                  flex-shrink: 0;
                  color: #fff;
                  background: #ff9626;
                  width: 5.4348rem;
                  height: 1.8116rem;
                  line-height: 1.8116rem;
                  border-radius: 0.2174rem;
                  text-align: center;
                "
              >
                团长福利
              </div>
              <div style="color: #4e4e4e; margin-left: 0.2899rem">
                {{ regularMembersData.tuan_ad_desc }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          height: 3.913rem;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #fff;
          box-sizing: border-box;
          padding: 0 0.8696rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.8696rem;
          font-weight: 400;
        "
      >
        <div @click="handleClickShowEarnings" style="color: #da2626">
          收益中心
        </div>
        <div @click="isShowMerchantsInfo = true" style="color: #626262">
          商家信息
        </div>
        <div
          @click="goActivityDetails"
          style="
            color: #f12b2b;
            border-radius: 1.087rem;
            border: 0.0725rem solid #f12b2b;
            padding: 0 0.8696rem;
            text-align: center;
            height: 2.029rem;
            line-height: 1.8841rem;
            box-sizing: border-box;
          "
        >
          返回活动
        </div>
        <div
          @click="handlePosterState(1)"
          style="
            color: #fff;
            border-radius: 1.087rem;
            background: #f12b2b;
            padding: 0 0.8696rem;
            text-align: center;
            height: 2.029rem;
            line-height: 1.8841rem;
            box-sizing: border-box;
          "
        >
          招募团员 立即赚钱
        </div>
      </div>
    </div>

    <!-- 邀好友 赢红包 -->
    <div v-if="isShowRedEnvelope">
      <div class="mask"></div>
      <div
        style="
          z-index: 3000;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div
          style="
            position: relative;
            background: #fc465e;
            width: 22.4638rem;
            height: 27.5362rem;
            border-radius: 0.3623rem;
            box-sizing: border-box;
            padding: 1.1594rem;
          "
        >
          <div style="color: #ffcc00; font-size: 1.1594rem; text-align: center">
            邀好友 赢红包
          </div>

          <div
            style="
              font-size: 0.942rem;
              background: #fff;
              border-radius: 0.2899rem;
              height: 3.9855rem;
              box-sizing: border-box;
              padding: 0.8696rem;
              margin-top: 1.8116rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div>
              <div style="color: #ac6500">邀请好友购买商品</div>
              <div style="color: #000; margin-top: 0.4348rem">
                {{ earningsData.good_info.good_name }}
              </div>
            </div>

            <div>
              <div style="color: #ff6600">
                {{ currentSelectLableType === 9 ? "员工佣金" : "一级分销" }}
              </div>
              <div style="color: #ac6500; margin-top: 0.4348rem">
                {{ earningsData.good_info.reward_pid }}元
              </div>
            </div>

            <div>
              <div style="color: #ff6600">
                {{ currentSelectLableType === 8 ? "员工分红" : "二级分销" }}
              </div>
              <div style="color: #ac6500; margin-top: 0.4348rem">
                {{ earningsData.good_info.reward_pids }}元
              </div>
            </div>
          </div>

          <div
            style="
              font-size: 0.942rem;
              background: #fff;
              border-radius: 0.2899rem;
              padding: 0.8696rem;
              margin-top: 0.7246rem;
            "
          >
            <div
              style="
                color: #ac6500;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
              "
            >
              <div style="margin-left: 2.8986rem; flex: 1">姓名</div>
              <div style="width: 6.5217rem">奖励</div>
            </div>

            <div style="height: 12.1739rem; overflow-y: auto">
              <template
                v-if="
                  currentSelectLableType !== 7 && currentSelectLableType !== 8
                "
              >
                <div
                  v-for="(item, index) in earningsData.list"
                  :key="index"
                  style="
                    color: #232323;
                    display: flex;
                    align-items: center;
                    margin-top: 0.7246rem;
                  "
                >
                  <!-- <div style="height: 1.8841rem; width: 1.8841rem; border-radius: 50%; margin-right: .942rem; background: #ac6500;"></div> -->
                  <img
                    :src="item.avatar"
                    alt=""
                    style="
                      height: 1.8841rem;
                      width: 1.8841rem;
                      border-radius: 50%;
                      margin-right: 0.942rem;
                    "
                  />
                  <div style="flex: 1">{{ item.nickname }}</div>
                  <div style="width: 6.5217rem; color: #ff6600">
                    {{ item.amount }}元
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(item, index) in earningsData.data"
                  :key="index"
                  style="
                    color: #232323;
                    display: flex;
                    align-items: center;
                    margin-top: 0.7246rem;
                  "
                >
                  <!-- <div style="height: 1.8841rem; width: 1.8841rem; border-radius: 50%; margin-right: .942rem; background: #ac6500;"></div> -->
                  <img
                    :src="item.avatar"
                    alt=""
                    style="
                      height: 1.8841rem;
                      width: 1.8841rem;
                      border-radius: 50%;
                      margin-right: 0.942rem;
                    "
                  />
                  <div style="flex: 1">{{ item.nickname }}</div>
                  <div style="width: 6.5217rem; color: #ff6600">
                    {{ item.amount }}元
                  </div>
                </div>
              </template>

              <div
                style="
                  color: #9b9b9b;
                  font-size: 0.8696rem;
                  text-align: center;
                  padding: 1.3043rem 0 0.8696rem;
                "
              >
                没有更多了
              </div>
            </div>
          </div>

          <img
            style="
              position: absolute;
              width: 4.7101rem;
              height: 2.1739rem;
              top: 0;
              left: 50%;
              transform: translate(-50%, -100%);
            "
            src="http://pp.ihycc.cn/image/liwu.png"
            alt=""
          />
          <img
            @click="handleRedEnvelopeState(0)"
            style="
              width: 1.6667rem;
              hieght: 1.6667rem;
              position: absolute;
              bottom: -1.1594rem;
              left: 50%;
              transform: translate(-50%, 100%);
            "
            src="../assets/close.png"
            alt=""
          />
          <img
            style="
              width: 4.7101rem;
              height: 2.1739rem;
              position: absolute;
              bottom: -3.913rem;
              left: 50%;
              transform: translate(-50%, 100%);
            "
            src="http://pp.ihycc.cn/image/liwu.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 审核员工 -->
    <el-dialog
      title="审核员工"
      :visible.sync="isShowAuditEmployees"
      width="21.7391rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="padding-bottom: 2.1739rem">
        <div style="color: #282728; font-size: 1.0145rem">
          待审核员工{{ auditEmployeesList.length }}人
        </div>

        <div style="height: 15.942rem; overflow-y: auto">
          <el-card
            v-for="(item, index) in auditEmployeesList"
            :key="index"
            style="margin-top: 1.0145rem; box-sizing: border-box"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <!-- <div style="width: 3.8406rem; height: 3.8406rem; background: #4372fc; margin-right: .8696rem; border-radius: 50%;"></div> -->
                <img
                  :src="item.avatar"
                  alt=""
                  style="
                    width: 3.8406rem;
                    height: 3.8406rem;
                    margin-right: 0.8696rem;
                    border-radius: 50%;
                  "
                />

                <div style="font-size: 0.8696rem">
                  <div style="color: #282728; font-size: 1.0145rem">
                    {{ item.realname }}
                  </div>
                  <div style="color: #2d5add">{{ item.telphone }}</div>
                  <div style="color: #484848">
                    微信昵称：{{ item.nickname }}
                  </div>
                </div>
              </div>

              <div
                style="
                  font-weight: 400;
                  line-height: 1.8116rem;
                  font-size: 0.8696rem;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                "
              >
                <div
                  @click="handleEmployeesState(item, 1)"
                  style="
                    margin-bottom: 0.7246rem;
                    color: #fff;
                    background: #537efc;
                    width: 5.4348rem;
                    height: 1.8116rem;
                    border-radius: 0.2899rem;
                    text-align: center;
                  "
                >
                  通过
                </div>
                <div
                  @click="handleEmployeesState(item, 2)"
                  style="
                    color: #7d7c7c;
                    background: #e4e4e4;
                    width: 5.4348rem;
                    height: 1.8116rem;
                    border-radius: 0.2899rem;
                    text-align: center;
                  "
                >
                  拒绝
                </div>
              </div>
            </div>
          </el-card>
          <div
            style="color: #929292; font-size: .942rem; text-align: center; margin: 1.8116rem; 0"
          >
            没有更多了
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 商家信息弹框 -->
    <el-dialog
      title="商家信息"
      :visible.sync="isShowMerchantsInfo"
      width="24.6377rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="font-weight: 400">
        <div v-for="(item, index) in merchants" :key="index">
          <div style="font-size: 1.087rem; color: #393939">{{ item.name }}</div>

          <div
            @click="handleShowMap(item)"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 0.7246rem;
              font-size: 1.0145rem;
            "
          >
            <div style="color: #787878">地址：{{ item.address }}</div>
            <!-- <div
              style="
                color: #fff;
                background: #ff7418;
                width: 5.7971rem;
                height: 1.7391rem;
                line-height: 1.7391rem;
                border-radius: 0.7246rem;
                text-align: center;
              "
            >
              导航
            </div> -->
          </div>

          <div
            v-for="(item1, index1) in item.telphone"
            :key="index1"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 0.7246rem;
              font-size: 1.0145rem;
            "
          >
            <div style="color: #787878">电话：{{ item1.value }}</div>
            <div
              @click="handleMakePhone(item1.value)"
              style="
                color: #fff;
                background: #00a0e8;
                width: 5.7971rem;
                height: 1.7391rem;
                line-height: 1.7391rem;
                border-radius: 0.7246rem;
                text-align: center;
              "
            >
              拨号
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 邀请员工 -->
    <el-dialog
      title="邀请员工"
      :visible.sync="isShowInvitationEmployees"
      width="21.7391rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="font-size: 0.942rem; text-align: center">
        <!-- <div style="width: 11.5942rem; height: 11.5942rem; border-radius: .2899rem; background: #e6e6e6; margin: auto;"></div> -->
        <div
          class="qrcode"
          ref="qrCodeUrl"
          style="
            width: 11.5942rem;
            height: 11.5942rem;
            border-radius: 0.2899rem;
            margin: auto;
          "
        ></div>

        <div style="color: #434343; margin: 0.942rem 0 1.087rem">
          员工扫此二维码申请加入
        </div>
        <div style="color: #f68624">长按保存二维码</div>
      </div>
    </el-dialog>

    <!-- 展示海报 -->
    <div v-if="isShowPoster">
      <div class="mask"></div>
      <div
        style="
          z-index: 3001;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 21.5217rem;
        "
      >
        <div
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <div
            style="
              color: #fff;
              font-size: 1.1594rem;
              background: #ff7418;
              padding: 0.3623rem 1.087rem;
              border-radius: 0.5072rem;
            "
          >
            长按图片保存相册
          </div>
          <img
            @click="handlePosterState(0)"
            style="width: 1.6667rem; height: 1.6667rem; margin-left: 2.8986rem"
            src="../assets/close.png"
            alt=""
          />
        </div>

        <div
          style="
            margin-top: 0.7246rem;
            background: #ffb400;
            border-radius: 1.087rem;
            height: 85vh;
            position: relative;
          "
        >
          <img
            v-if="postersUrl"
            style="width: 100%; height: 100%"
            :src="postersUrl"
            alt=""
          />
          <div
            v-else
            style="
              color: #f7f2f3;
              font-size: 1.0145rem;
              text-align: center;
              position: absolute;
              width: 100%;
              top: 10%;
            "
          >
            海报背景-由设计师审核的时候设计上传
          </div>
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3001;
          color: #fff;
          font-weight: 400;
          font-size: 0.8696rem;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: 0.2899rem;
          padding: 0.7246rem 1.087rem;
        "
      >
        {{ hintContent }}
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { randomString } from '@/utils/common'
export default {
  name: "Management",
  components: {

  },
  data() {
    return {
      // 是否显示 邀好友 赢红包
      isShowRedEnvelope: false,
      // 是否显示 审核员工 弹框
      isShowAuditEmployees: false,
      // 是否显示 邀请员工 弹框
      isShowInvitationEmployees: false,
      // 用户信息
      userInfo: {},
      // 当前活动列表的页码 
      currentPageIndex: 1,
      // 活动的列表
      activityList: [],
      // 复制的内容
      copyText: '',
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      // 生成的二维码
      qrCode: null,
      // 审核员工的列表
      auditEmployeesList: [],
      // 当前点击的标签  8: 员工分红  9: 员工佣金
      currentSelectLableType: -1,
      // 活动id
      id: "",
      // 邀请人id
      invite_id: "",
      // 普通会员的数据
      regularMembersData: {},
      // 倒计时的定时器
      countdownTimer: null,
      // 当前的倒计时内容
      countdown: {
        day: 0,
        hour: 0,
        minute: 0,
        seconds: 0,
      },
      // 是否显示商家信息弹框 
      isShowMerchantsInfo: false,
      // 商家信息
      merchants: [],
      // 收益信息
      earningsData: {},
      isShowPoster: false,
      postersUrl: "",
    }
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.invite_id) {
      this.id = parseInt(this.$route.query.id);
      this.invite_id = parseInt(this.$route.query.invite_id);
    }
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.currentPageIndex = 1;
    // 通过角色类型 调用对应接口  admin_group_id -  0: 普通会员 1: 管理员/导师 2:设计师， 3:员工
    this.getActivityList(this.userInfo.admin_group_id);
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer);
  },
  methods: {
    /** 处理海报的显示与关闭  type: 0 关闭  1 打开 */
    handlePosterState(type) {
      this.isShowPoster = type == 1;
      if (this.isShowPoster) {
        this.getPostersContent();
        // this.$nextTick(() => {
        //     this.creatQrCode();
        // });
      }
    },

    /** 获取活动海报内容 */
    getPostersContent() {
      this.$axios.post("/index/getshareImage", {
        id: this.id,
        invite_id: this.invite_id,
      }).then(res => {
        this.postersUrl = res.image_url;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 处理点击查看收益 */
    handleClickShowEarnings() {
      this.$axios.post("/user/myrewardByEvent", {
        event_id: this.regularMembersData.id,
        good_id: this.regularMembersData.good.id,
      }).then(res => {
        this.earningsData = res;
        this.currentSelectLableType = -1;
        this.isShowRedEnvelope = true;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 处理点击拨号 */
    handleMakePhone(phone) {
      window.location.href = 'tel:' + phone;
    },

    /** 处理点击导航展示的地图 */
    handleShowMap(item) {
      if (item.lat && item.lng && item.city) {
        location.href = `https://apis.map.qq.com/tools/poimarker?type=1&keyword=${item.city}&center=${item.lat},${item.lng}&radius=1000&key=C3SBZ-LTHCX-AIT4Q-TRZQV-MY6TH-QVFKO&referer=myapp`;
      }
      else if (item.address && item.city) {
        location.href = `https://apis.map.qq.com/tools/poimarker?type=1&keyword=${item.city}&region=${item.address}&radius=1000&key=C3SBZ-LTHCX-AIT4Q-TRZQV-MY6TH-QVFKO&referer=myapp`;
      }
    },

    /** 去活动详情页面 */
    goActivityDetails() {
      this.$router.push({ path: "/event_"+randomString()+"?id=" + this.id + "&invite_id=" + this.invite_id });
    },

    /** 处理点击独立成团 */
    handleSetupTeam() {
      if (this.regularMembersData.user_id === this.regularMembersData.member_id) {
        return;
      }

      // let order_id = -1;
      // this.regularMembersData.tuans.forEach((item, index) => {
      //     if(item && this.regularMembersData.user_id === item.member_id) {
      //         order_id = this.regularMembersData.tuans[index].id;
      //     }
      // });
      // if(order_id === -1) {
      //     this.showMessage("当前还没有参团");
      //     return;
      // }

      this.$axios.post("/events/OrderGroupApply", {
        event_id: this.id,
        group_id: this.regularMembersData.id,
        order_id: this.regularMembersData.self_order_id,
      }).then(res => {
        this.showMessage(res);
        this.getActivityList(this.userInfo.admin_group_id);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 处理点击计算 */
    handleCalculateData(item, index) {
      this.$axios.post("/events/shareOrderTotalByList", {
        event_id: item.id,
        min_number: 2,
      }).then(res => {
        this.$set(this.activityList, index, { ...this.activityList[index], totalPeopleCount: res.total });
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 监听滚动事件 */
    handleScroll() {
      if (this.pageTotal > this.currentPageIndex * 15) {
        this.currentPageIndex++;
        this.getActivityList(this.userInfo.admin_group_id);
      }
    },

    /** 处理点击标签  0: 员工数据  1: 邀请员工  2: 审核员工  3: 管理员工  4: 成功报名  5: 潜在客户  6：全部组队  7: 全部单买  8: 员工分红  9: 员工佣金 */
    handleClickLabel(type, item) {
      if (type === 0) {
        this.$router.push({ path: '/employeesData?id=' + item.id });
      }
      else if (type === 1) {
        this.isShowInvitationEmployees = true;
        this.handleInviteEmployees(item.id);
      }
      else if (type === 2) {
        this.isShowAuditEmployees = true;
        this.getAuditEmployeesList(item.id);
      }
      else if (type === 3) {
        this.goEmployeesManagement(item.id);
      }
      else if (type === 4 || type === 5 || type === 6 || type === 7) {
        this.$router.push({ path: "/customerData?id=" + item.id + "&type=" + type });
      }
      else if (type === 8) {
        this.currentSelectLableType = type;
        this.getEmployeesMoneyData(item, type);
      }
      else if (type === 9) {
        this.$router.push({ path: "/employeesBill?event_id=" + item.id });
      }
    },

    /** 获取 员工分红、员工佣金 数据 */
    getEmployeesMoneyData(item, type) {
      this.$axios.post("/teams/getEventAdminReward", {
        event_id: item.id,
        class_id: type === 8 ? 1 : type === 9 ? 2 : "",
        good_id: item.goods[0].id,
      }).then(res => {
        this.isShowRedEnvelope = true;
        this.earningsData = res;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 去管理员工页面 */
    goEmployeesManagement(id) {
      this.$router.push({ path: '/employeesManagement?id=' + id });
    },

    /** 处理对审核员工的同意或拒绝 - 1 通过 2 拒绝 */
    handleEmployeesState(item, type) {
      this.$axios.post("/admins/doApplyJob", {
        is_type: type,
        id: item.id,
        event_id: item.event_id,
      }).then(() => {
        this.showMessage(type === 1 ? '已通过' : '已拒绝');
        this.getAuditEmployeesList(item.event_id);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 获取审核员工的列表 */
    getAuditEmployeesList(id) {
      this.$axios.post("/admins/getApplyList", {
        event_id: id,
      }).then(res => {
        this.auditEmployeesList = res;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 创建二维码 */
    creatQrCode(apply_code, id) {
      let url = location.href.endsWith('/management') ? location.href.slice(0, -10) : location.href
      console.log('url===>', url)
      if (this.qrCode !== null) {
        this.qrCode.makeCode(url + "employeesJoin?apply_code=" + apply_code + "&id=" + id);
        return;
      }
      this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
        text: url + "employeesJoin?apply_code=" + apply_code + "&id=" + id,
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    /** 导师邀请员工的接口 */
    handleInviteEmployees(id) {
      this.$axios.post("/user/applyStaffLink", {
        event_id: id,
      }).then(res => {
        this.creatQrCode(res.apply_code, id);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 获取活动列表  type - 0: 普通会员 1: 管理员/导师 2:设计师， 3:员工 */
    getActivityList(type) {
      // 获取导师的活动列表
      if (type === 1) {
        this.$axios.post("/events/getAllByAdmin", {
          page: this.currentPageIndex,
        }).then(res => {
          this.pageTotal = res.total;
          this.activityList.push(...res.data);
        }).catch(error => {
          this.showMessage(error);
        });
      }
      // 获取员工的活动列表
      else if (type === 3) {
        this.$axios.post("/events/getAllByTeam", {
          page: this.currentPageIndex,
        }).then(res => {
          this.activityList.push(...res.data);
        }).catch(error => {
          this.showMessage(error);
        });
      }
      // 获取家长后台
      else if (type === 0) {
        this.$axios.post("/User/eventOrderInfo", {
          event_id: this.id,
        }).then(res => {
          let tuans = [
            res.tuans && res.tuans[0] ? res.tuans[0] : null,
            res.tuans && res.tuans[1] ? res.tuans[1] : null,
            res.tuans && res.tuans[2] ? res.tuans[2] : null,
          ];

          let poorPeopleCount = 0;
          tuans.forEach(item => { poorPeopleCount = item === null ? poorPeopleCount + 1 : poorPeopleCount });

          this.regularMembersData = {
            ...res,
            tuans,
            poorPeopleCount,
          }

          this.merchants = res.schools.map(item => ({ ...item, telphone: JSON.parse(item.telphone) }));

          // 活动倒计时
          this.countdownTimer = setInterval(() => {
            const now = new Date().getTime();
            const end = parseInt(res.end_time.toString().padEnd(13, '0'));
            const date = end - now;
            let day, hour, minute, seconds;

            if (date >= 0) {
              day = Math.floor(date / 1000 / 60 / 60 / 24).toString().padStart(2, '0');
              hour = Math.floor(date / 1000 / 60 / 60 % 24).toString().padStart(2, '0');
              minute = Math.floor(date / 1000 / 60 % 60).toString().padStart(2, '0');
              seconds = Math.floor(date / 1000 % 60).toString().padStart(2, '0');
              this.countdown = { day, hour, minute, seconds };
            }
            else {
              clearInterval(this.countdownTimer);
            }
          }, 1000);
        }).catch(error => {
          this.showMessage(error);
        });
      }
    },

    /** 处理 邀好友 赢红包 状态  type: 0 关闭 1 打开 */
    handleRedEnvelopeState(type) {
      this.isShowRedEnvelope = type === 1;
    },

    /** 统一处理关闭弹框 */
    handleClose(done) {
      done();
    },

    /** 处理活动详情页面 */
    handleGoActivity(item) {
      // import { isExistValOfLocalStorage,randomString } from '../utils/common'
      this.$router.push({ path: "/event_"+randomString()+"?id=" + item.id + "&invite_id=" + this.userInfo.id });
    },

    /** 处理点击分享链接操作 */
    handleShareLink(item) {
      this.copyText = "http://pp.ihycc.cn/event_"+randomString()+"?id=" + item.id + "&invite_id=" + this.userInfo.id;
      new this.Clipboard('.copy-text');


      // 设置成功
      this.showMessage("右上角分享");
      this.$axios.post("/Wechat/getShareConfig", {
        url: window.location.href,
        event_id: item.id
      }).then(res => {
        this.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名
          jsApiList: ["onMenuShareAppMessage"] // 必填，需要使用的JS接口列表
        });
        this.wx.ready(() => {
          let title = item.share_title ? item.share_title : ''
          let desc = item.share_content ? item.share_content : ""
          let link = "http://pp.ihycc.cn/event_"+randomString()+"?id=" + item.id + "&invite_id=" + this.userInfo.id
          let imgUrl = item.sahre_image ? item.sahre_image : ''
          this.wx.onMenuShareAppMessage({
            // 分享标题
            title,
            // 分享描述
            desc,
            // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            link,
            // 分享图标
            imgUrl,
            success: () => {
              this.handleStatisticsShare();
            },
          });
          // 分享到朋友圈
          // this.wx.onMenuShareTimeline({
          //      // 分享标题
          //     title, 
          //     // 分享描述
          //     desc, 
          //     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          //     link,
          //     // 分享图标
          //     imgUrl,
          //     success: () => {
          //         this.handleStatisticsShare();
          //     },
          // })
        });
      });

      // this.copyText = location.href.replace(this.$route.path, "/show") + "?id=" + item.id + "&invite_id=" + this.userInfo.id;
      // const clipboard = new this.Clipboard('.copy-text');

      // clipboard.on('success', () => {
      // this.hintContent = "复制成功";
      // this.isShowHint = true;
      // clipboard.destroy();
      // const timeout = setTimeout(() => {
      //     this.isShowHint = false; 
      //     clearTimeout(timeout);
      // }, 1000);
      // });
      // clipboard.on('error', () => {
      //     this.hintContent = "该浏览器不支持自动复制";
      //     this.isShowHint = true;  
      //     clipboard.destroy();
      //     const timeout = setTimeout(() => {
      //         this.isShowHint = false;
      //         clearTimeout(timeout);
      //     }, 1000);
      // })
    },

    /** 统计用户分享操作 */
    handleStatisticsShare() {
      this.$axios.post("/events/addShareClick", {
        event_id: this.id,
      });
    },

    /** 去添加活动页面 */
    goAddActivity(item) {
      let strUrl = item && item.id ? "?id=" + item.id : "";
      if (item.id && this.userInfo.admin_group_id === 0) {
        strUrl += "&invite_id=" + this.invite_id;
      }
      this.$router.push({ path: '/addActivity' + strUrl });
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },

    /** 退出登录 */
    handleLogOut() {
      localStorage.clear();
      this.$router.go(0);
    },
  }
};
</script>

<style scoped lang="scss">
.Management {
  background: #f5f5f5;
  // min-height: 100vh;
  // height: 100%;
  font-weight: bold;
  height: 100vh;
  overflow: hidden;

  .infinite-list-item {
    margin: 0 15px 15px;
    box-sizing: border-box;
    position: relative;
  }

  .list_title {
    margin-left: 0.7246rem;
    position: relative;
    padding-left: 0.5797rem;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      height: 1.0145rem;
      width: 0.2174rem;
      background: #4372fc;
    }
  }

  .list_con {
    margin-top: 0.7246rem;
    padding: 1.087rem;
    background: #fff;
    border-radius: 0.2899rem;
    box-shadow: 0 0 0.4348rem #c6c3c3;

    .item_name {
      // display: inline-block;
      color: #4b4b4b;
      font-size: 14px;
      // margin-top: 0.942rem;
      margin-bottom: 10px;
      // border: 0.0725rem solid #2b5fd5;
      background: #e8edfc;
      padding: 0.7246rem;
      border-radius: 4px;
    }

    .item-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      color: #a8a8a8;
      font-size: 0.7246rem;
      span {
        color: #666;
      }
    }

    .item-btns {
      border-top: 1px solid #e8e8e8;
      margin-top: 10px;
      color: #2b5fd5;
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;
      line-height: 2.5362rem;
    }
    .item-btn {
      margin-right: 10px;
      margin-top: 10px;
      width: 22%;
      height: 30px;
      line-height: 30px;
      border: 1px solid #2b5fd5;
      border-radius: 4px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

::v-deep .el-dialog {
  border-radius: 0.3623rem;
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    padding-top: 0.7246rem;
  }
}

::v-deep .el-card__body {
  padding: 1.087rem 0.5072rem !important;
}

._copy-style {
  background: #ebf3ff;
  border-radius: 0.2899rem;
  border: 0.0725rem solid #4372fc;
}

table {
  border-collapse: collapse;
  border-spacing: 0 !important;
  thead {
    border-color: transparent !important;
  }
  tbody {
    th,
    td {
      border: 0.0362rem solid #cccccc;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.add {
  position: relative;
  &::before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5942rem;
  }
}
</style>
